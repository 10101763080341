<script setup lang="ts">
defineOptions({
  inheritAttrs: false
});

const { loading = false, elementsLength } = defineProps<{
  loading?: boolean | Ref<boolean> | undefined;
  direction?: "up" | "down";
  elementsLength: number;
}>();

const emit = defineEmits<{
  (e: "loadMore"): void;
}>();

const triggerUp = ref(null);
const triggerDown = ref(null);
const isVisible = ref(false);

useIntersectionObserver(triggerUp, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting && !loading) {
    emit("loadMore");
  }
});
useIntersectionObserver(triggerDown, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting && !loading) {
    emit("loadMore");
  }
});

watch(
  () => elementsLength,
  () => {
    if (isVisible.value) {
      emit("loadMore");
    }
  }
);
</script>

<template>
  <div ref="triggerUp" class="min-h-px" />
  <slot />
  <div ref="triggerDown" class="min-h-px" />
</template>
